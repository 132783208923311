const header = async () => {
  const currentUrlParams = new URLSearchParams(window.location.search);

  const appendQueryParams = url => {
    const urlObj = new URL(url);
    currentUrlParams.forEach((value, key) => {
      urlObj.searchParams.append(key, value);
    });
    return urlObj.toString();
  };

  const envValues = {
    login: appendQueryParams(`${process.env.BASE_URL_GASALLIN}/auth/login`),
    registration: appendQueryParams(
      `${process.env.BASE_URL_GASALLIN}/auth/register`
    ),
    joinNow: appendQueryParams(
      `${process.env.BASE_URL_GASALLIN}/auth/register`
    ),
    telegram: `${process.env.GASALLIN_TELEGRAM}`,
    telegram_bot: `${process.env.GASALLIN_TELEGRAM_BOT}`,
    telegram_private: `${process.env.GASALLIN_TELEGRAM_PRIVATE}`,
    stalwart: `${process.env.STALWART}`,
    algoritmic_lab: `${process.env.ALGORITMIC_LAB}`,
    yahoo: `${process.env.YAHOO}`,
    benzinga: `${process.env.BENZINGA}`,
    insider: `${process.env.INSIDER}`,
    msn: `${process.env.MSN}`,
    contract: `${process.env.CONTRACT_BSC}`,
    metamask: `${process.env.METAMASK}`,
    trust_wallet: `${process.env.TRUST_WALLET}`,
  };

  const serviceElements = document.querySelectorAll('[data-service]');
  serviceElements.forEach(service => {
    const dataService = service.getAttribute('data-service');
    service.href = envValues[dataService];
  });
};

document.addEventListener('DOMContentLoaded', async () => {
  await header();
});
